import styled from "styled-components"
export const ContactStyled = styled.div`
  .pt-9 {
    padding-top: 9px;
  }
  .accordion-section {
    padding: 0 0 40px 0;
  }
  .contact-wrapper {
    padding: 0 0 80px 0;
  }
  h1 {
    font-size: 37px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
  }
  p {
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    line-height: 27px;
  }
  .subtitle-head {
    font-weight: 500;
  }
  p:nth-of-type(2) {
    padding: 9px 0 23px 0;
  }
  .heading {
    padding: 0;
  }
  .container-fluid {
    padding: 0;
  }
  width: 100%;
  .bg-gray {
    background-color: #f8f8f8;
  }
  .buttons-call {
    padding: 46px 0 80px 0;
    .container {
      a,
      a button {
        width: 100%;
        max-width: none;
        span {
          width: 100%;
          text-align: center;
        }
      }
      a:first-child button {
        margin-bottom: 14px;
      }
    }
  }
  @media (min-width: 2560px) {
    min-height: calc(100vh - 524px - 155px) !important;
    & > div.container {
      //position: relative;
    }
  }
  @media (min-width: 1200px) {
    margin-top: 125px;
    min-height: 940px;
    .contact-wrapper {
      padding-right: 80px;
      padding-left: 0;
    }
    .heading {
      h1 {
        font-size: 48px;
        margin-bottom: 40px;
      }
      p:nth-of-type(2) {
        padding-bottom: 20px;
      }
      .subtitle-head {
        margin-bottom: 25px;
      }
    }
    .bg-form {
      background-color: #f8f8f8;
      padding: 0 80px 0 80px;
      position: absolute;
      width: 50%;
      right: 0;
      top: 155px;
      @media (min-width: 2560px) {
        top: 0;
      }
      &:after {
        content: "";
        position: absolute;
        top: -50vw;
        z-index: -1;
        left: 0;
        background-color: #f8f8f8;
        width: 100%;
        height: 1500vh;
      }
    }
    .form-content {
      p.form-subtitle {
        /* width: 75%; */
      }
    }
  }
  .form-container-parent {
    padding: 0;
    padding-left: 80px;
  }

  .contactV2--wrapper {
    width: 100%;
    .office--info {
      font-weight: 500;
      padding: 20px 0;
      height: 100%;
      margin-bottom: 2px;
      font-size: 20px;
      border-bottom: 1px solid #dadada;
      width: 100%;
      .contactV2--title {
        .icon {
          width: 39px;
          img {
            max-height: 26px;
          }
        }
      }
    }
    .subtitle {
      font-weight: 500;
      border-color: #dadada !important;
      font-size: 20px;
      text-transform: uppercase;
      padding-bottom: 15px;
      padding-top: 8px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .narrower {
      width: 195px;
      font-size: 17px;
      .icon-email {
        width: 60px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .contact--sales_buttons {
      padding-left: 0;
      :first-of-type {
        margin-bottom: 1em;
      }
    }
  }
  @media screen and (max-width: 360px) {
    // .narrower {
    //   width: 195px;
    //   font-size: 17px;
    //   .icon-email {
    //     width: 60px;
    //   }
    // }
    .mob-p0 {
      padding: 0;
    }
  }
`

export const AccordionStyled = styled.div`
  .accordion {
  }
  .card {
    border: none;
    padding: 0;
    border-radius: 0;
    //border-bottom: 1px solid #dadada !important;
  }
  .card:first-child {
  }
  .card-body .subtitle {
    color: #000 !important;
    font-weight: 500;
  }
  .card-body .buttons {
    display: flex;
    flex-direction: column;
    padding: 30px 0 15px 0;
    a {
      width: 100%;
    }
    a:first-child {
      margin-bottom: 20px;
    }
  }
  .card-body {
    padding-left: 0px;
    padding-right: 0;
  }
  .card-header {
    border: none;
    background-color: #fff;
    padding: 0;
    user-select: none;
    cursor: pointer;
  }
  .subtitle {
    border-color: #dadada !important;
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 15px;
    padding-top: 8px;
  }
  .flex > div {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .card-body .buttons {
      flex-direction: row;
    }
  }
  @media (min-width: 1440px) {
    .card-body .buttons {
      flex-direction: row;
      a:first-child {
        margin-bottom: 0;
      }
    }
  }
`

export const SingleAccordContact = styled.div`
  background: transparent;
  color: #000;
  transition: 300ms;
  // font-weight: ${props => (props.active ? "500" : "400")};
  font-weight: 500;
  padding: 20px 0;
  height: 100%;
  margin-bottom: 2px;
  position: relative;
  border-bottom: 1px solid
    ${props => (props.active ? "transparent" : "#dadada")};
  font-size: 20px;
  .icon {
    width: 39px;
    display: flex;
    align-items: center;
  }
  img {
    max-height: 26px;
  }
  &:after {
    transition: 300ms;
    position: absolute;
    right: 8px;
    top: 47%;
    transform: translateY(-50%);
    content: "";
    width: 12px;
    height: 12px;
    opacity: 0.2;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(${props => (props.active ? "135deg" : "45deg")});
    display: ${props => (props.showStatus ? "none" : "initial")}
  }
    .accordion-title {
      display: flex;
    }
  div.status {
    display: ${props => (props.showStatus ? "initial" : "none")};
    color: ${props => (props.officeOpen ? "#94C835" : "red")};
    color: #94c835;
    border: ${props =>
      props.officeOpen ? "1px solid #94C835" : "1px solid red"};
    border: 1px solid #94c835;
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .office-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  @media(max-width: 432px) {
  &:after {
      display: initial;
  }
  .office-info {
      display: inline-block;
     .accordion-title {
      margin-bottom: 10px;
     }
  }
  }
`
