import React from "react"
import Layout from "../../components/layout"
import Contact from "../../components/contact/contact"
import Helmet from "react-helmet"

const ContactPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | Kontakt</title>
    </Helmet>
    <Layout>
      <Contact />
    </Layout>
  </>
)

export default ContactPage
